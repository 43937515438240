<template>
  <card-form>
    <div class="row mt-4">
      <div class="col-md-5 col-xxl-3" data-vv-as="Data do envio*">
        <fieldset class="form-group mb-3 form-group-input">
          <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">Data do envio</legend>
          <div class="d-inline-flex align-items-start">
            <label>
              <FDateInput
                name="periodoDe"
                v-model="model.periodoDe"
                v-validate="{ required: true }"
                data-vv-as="Data de início do envio"
                :class="{
                  'form-control is-invalid pb-0 pt-0 ps-0': isDisplayErrors('periodoDe'),
                }"
              />
              <span class="text-danger" v-if="isDisplayErrors('periodoDe')">{{ errors.first('periodoDe') }}</span>
            </label>
            <span class="mx-1 pl-1 pr-1 pt-2"> a </span>
            <label>
              <FDateInput
                name="periodoAte"
                v-model="model.periodoAte"
                v-validate="{ required: true }"
                data-vv-as="Data fim de envio"
                :class="{
                  'form-control is-invalid pb-0 pt-0 ps-0': isDisplayErrors('periodoAte'),
                }"
              />
              <span class="text-danger" v-if="isDisplayErrors('periodoDe')">{{ errors.first('periodoDe') }}</span>
            </label>
          </div>
        </fieldset>
      </div>
      <f-selectsearch 
        label="Convênio*"
        name="convenioId"
        ref="convenioId"
        v-model="model.convenioId"
        :multiple="false"
        :cols="3"
        :searchFunction="consultarConvenio"
        @select="addConvenio"
        v-validate="{required: true}"
        data-vv-as="Convênio"
        :modelErrors="errors"
      />
      <f-selectsearch 
        label="Hospital(is)"
        name="hospitaisIds"
        ref="hospitaisIds"
        v-model="model.hospitaisIds"
        :multiple="true"
        :cols="4"
        :searchFunction="consultarHospital"
        @select="addHospital"
      />
      <f-input 
        name="lote" 
        v-model="model.lote" 
        data-vv-as="Lote"
        label="Lote"
        :modelErrors="errors"
        :cols="3"
      />
      <f-input 
        name="guia" 
        v-model="model.guia"
        data-vv-as="Guia"
        label="Guia"
        :modelErrors="errors"
        :cols="3"
      />
      <f-selectsearch 
        label="Tipo de Guia*"
        name="tipoGuiaId"
        ref="tipoGuiaId"
        v-model="model.tipoGuiaId"
        valueField="codigo"
        :multiple="false"
        :cols="3"
        :searchFunction="listarTipoGuia"
        :searchAllOptions="true"
        :removeOptionsOnSelect="false"
        @select="addTipoGuia"
        data-vv-as="Tipo de Guia"
        v-validate="{ required: true }"
        :modelErrors="errors"
      />
      <f-selectsearch 
        label="Tipo Atendimento"
        name="tipoAtendimentoIds"
        ref="tipoAtendimentoIds"
        v-model="model.tipoAtendimentoIds"
        valueField="codigo"
        :multiple="true"
        :cols="3"
        :searchFunction="listarTipoAtendimentoPorGuiaId"
        :searchAllOptions="true"
        :removeOptionsOnSelect="false"
        @select="addTipoAtendimento"
      />
      <f-input 
        name="carteira" 
        v-model="model.carteira"
        data-vv-as="Carteira"
        label="Carteira"
        :modelErrors="errors"
        :cols="3"
      />
      <f-selectsearch 
        label="Tipo(s) de internação"
        name="tipoInternacaoIds"
        ref="tipoInternacaoIds"
        v-model="model.tipoInternacaoIds"
        valueField="codigo"
        :multiple="true"
        :cols="3"
        :searchFunction="listarTipoInternacao"
        :searchAllOptions="true"
        :removeOptionsOnSelect="false"
        @select="addTipoInternacao"
      />
      <f-selectsearch 
        label="Efetivo/Urgência"
        name="caraterAtendimentoIds"
        ref="caraterAtendimentoIds"
        valueField="codigo"
        v-model="model.caraterAtendimentoIds"
        :cols="3"
        :multiple="true"
        :searchFunction="listarCaraterAtendimento"
        :searchAllOptions="true"
        :removeOptionsOnSelect="false"
        @select="addCaraterAtendimento"
      />
      <f-selectsearch 
        label="Tipo(s) de faturamento"
        name="tipoFaturamentoIds"
        ref="tipoFaturamentoIds"
        v-model="model.tipoFaturamentoIds"
        valueField="codigo"
        :multiple="true"
        :cols="3"
        :searchFunction="listarTipoFaturamento"
        :searchAllOptions="true"
        :removeOptionsOnSelect="false"
        @select="addTipoFaturamento"
      />
    </div>

    <template #footer>
      <button v-if="$hasPerm('relAuditoriaRetrospectiva')" class="btn btn-success px-5" @click="gerarRelatorio" type="button">
        Gerar Relatório
      </button>
    </template>
  </card-form>
</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import FSelectsearch from '@/components/Form/SelectSearch';
import FDateInput from '@/components/Form/DateInput';
import {
  downloadRelatorioGlosas
} from "@/services/relatorio-glosas.service";
import {
  pesquisarConvenio, pesquisarHospital, listarTipoGuia, listarTipoAtendimento,
  listarTipoInternacao, listarCaraterAtendimento, listarTipoFaturamento
} from "@/services/relatorios.service";
import { messageErrorForm } from '@/utils/messages';

export default {
  components: { CardForm, FSelectsearch, FDateInput, },
  data() {
    return {
      listarTipoInternacao,
      listarCaraterAtendimento,
      listarTipoFaturamento,
      listarTipoGuia,
      model: {
        periodoDe: null,
        periodoAte: null,
        uploadDe: '',
        uploadAte: '',
        convenioId: '',
        hospitaisIds: [],
        lote: '',
        guia: '',
        tipoGuiaId: '',
        tipoAtendimentoIds: [],
        carteira: '',
        tipoInternacaoIds: [],
        caraterAtendimentoIds: [],
        tipoFaturamentoIds: [],
      },
    };
  },
  methods: {
    addCaraterAtendimento(escolha) {
      this.model.caraterAtendimentoIds = escolha.map(m => m.codigo);
    },
    addConvenio(convenio) {
      this.model.convenioId = convenio?.id;
    },
    addHospital(hospital) {
      this.model.hospitaisIds = hospital.map(m => m.id);
    },
    addTipoFaturamento(tipoFaturamento) {
      this.model.tipoFaturamentoIds = tipoFaturamento.map(m => m.codigo);
    },
    addTipoInternacao(tipoInternacao) {
      this.model.tipoInternacaoIds = tipoInternacao.map(m => m.codigo);
    },
    addTipoGuia(tipoGuia) {
      this.model.tipoGuiaId = tipoGuia?.codigo;

      // Reseta todas as informações em tipo-atendimento (campo que depende fortemente do tipo de guia.)
      this.model.tipoAtendimentoIds = [];
      this.$refs.tipoAtendimentoIds.setInitialValue([]);
      this.$refs.tipoAtendimentoIds.asyncFind('', true);
    },
    addTipoAtendimento(tipoAtendimento) {
      this.model.tipoAtendimentoIds = tipoAtendimento.map(m => m.codigo);
    },
    consultarConvenio(query) {
      return pesquisarConvenio(query, this.conveniosIds);
    },
    consultarHospital(query) {
      return pesquisarHospital(query, this.hospitaisIds);
    },
    async gerarRelatorio() {
      var isValid = await this.$validator.validateAll();
      if (isValid) {
        downloadRelatorioGlosas(this.model).catch(err => {
          messageErrorForm(this, err, "Falha na geração do relatório. Por favor, verifique os filtros utilizados.");
        });
      }
    },
    isDisplayErrors(tag) {
      return this.errors && this.errors.has(tag);
    },
    listarTipoAtendimentoPorGuiaId() {
      return listarTipoAtendimento(this.model.tipoGuiaId);
    },
  },
};
</script>